// 游玩页, 用于对局模拟

function Play() {
  return <div className="bp-main-panel" style={{
    backgroundImage: 'url("/static/bg/bp_bg.png")',
  }}>
    <p style={{
      color: 'white',
      fontSize: '30px',
    }}>
      正在尝试使用谷雨同学的核心库进行开发，目前还在施工中。
    </p>
    <p style={{
      color: 'white',
      fontSize: '30px',
    }}>
      谷雨同学已经有了可用的版本，可以从以下链接访问试用：
      <br />
      <a href="https://gi-tcg.vercel.app">https://gi-tcg.vercel.app</a>
      <br />
      <a href="https://gi-tcg.guyutongxue.site">https://gi-tcg.guyutongxue.site</a>
    </p>
  </div>
}

export default Play;
